import { createComponent } from '@lib/util/templateHelpers';

import styles from './index.module.scss';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

export default createComponent('AppHeader', { styles }, function AppHeader ({ className, slots }, props) {
  return (
    <header className={className}>
      <div className='AppHeader__Main'>
        <MobileHeader className='MobileHeader'>
          {slots && slots.mobile}
        </MobileHeader>
        <DesktopHeader className='DesktopHeader Flex--alignCenter'>
          {slots && slots.desktop}
        </DesktopHeader>
      </div>
    </header>
  );
});
