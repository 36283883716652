import { useMemo } from 'react';

const gameOptions = {
  1: 'mr-sword', // Sunday
  0: 'roidz', // Monday
};

export default function MiniHeaderGames() {
  const gamePath = '/house-games/';
  const htmlFile = '/index.html';
  const iFrameId = 'iog-mini-header-game';

  const gameUrl = useMemo(() => {
    const gameArray = Object.values(gameOptions);
    return gameArray.length > 1
      ? gameArray[new Date().getDay() % gameArray.length] // Cycle games if multiple exist
      : gameArray[0]; // Use the only game available
  }, []);

  const fullGameUrl = `${gamePath}${gameUrl}${htmlFile}`;

  return (
    <object
		id={iFrameId}
      data={fullGameUrl}
      type="text/html"
      style={{
        width: '750px',
        height: '100px',
        margin: '0 1rem',
        border: 'none',
      }}
    ></object>
  );
}