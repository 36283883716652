import Link from 'next/link';

import { If, createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
import { useLogout } from '@/hooks/logout';

import MiniHeaderGames from '@/components/AppHeader/MiniHeaderGames';

const { Flex, Button, Container } = shared;

import styles from './index.module.scss';
import IoSearch from '@/components/IoSearch';
import DesktopMenu from '@/components/AppHeader/DesktopMenu';

// const menuitems = process.env.APP_CONFIG.DESKTOP_MENU_LINKS;
const logoPath = process.env.APP_CONFIG.LOGO_PATH;

export default createComponent('AppHeader__Desktop', { styles }, function DesktopHeader ({ className }, props) {

  return (
    <Container className={className} wide>
      <Flex wide justifySpaceBetween>
        <Flex alignCenter>
          {props.children}
          <Link href='/'>
            {/* Intentionally squished markup here so no whitespace */}
            <a className='AppHeader__Desktop__Logo' style={{fontSize: 0}}><img
              src={logoPath}
              alt='iogames.space'
              width={190}
              height={28}
            /></a>
          </Link>
        </Flex>
		{/* <div className='Flex Flex--alignCenter'
			 style={{
				backgroundColor: 'coral',
				color: 'white',
				padding: '15px',
				boxSizing: 'border-box',
				border: '2px solid black',
				width: '750px',
				height: '100px',
				margin: '0 1rem',
			}}
		>
		<p className='--textCenter --wide'>GAME</p>
		</div> */}
        {/* <Flex twoThirds>
          <DesktopMenu menuitems={menuitems}/>
        </Flex> */}
		<MiniHeaderGames />
        <Flex className='Flex--alignCenter'>
          <IoSearch placeholder='Search io Games' />
        </Flex>
            </Flex>

    </Container>
  );
});
