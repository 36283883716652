import { formatDistance } from 'date-fns';
import { parse } from 'node-html-parser';

export function formatGame (game, included, meta) {
  const thumbnailField = included && included.find((field) => field.id === game.relationships?.field_thumbnail?.data?.id);

  const thumbnailUrl = game.field_thumbnail
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + game.field_thumbnail
    : (thumbnailField?.attributes?.uri?.url
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailField.attributes.uri.url
      : '/images/defaults/IndexThumbnail.png');

  const promoField = included && included.find((field) => field.id === game.relationships?.field_promo_image?.data?.id);

  const promoUrl = game.field_promo_image 
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + game.field_promo_image
    : (promoField?.attributes?.uri?.url
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + promoField.attributes.uri.url
      : false);

  const videoThumbnailField = included && included.find((field) => field.id === game.relationships?.field_video_thumbnail?.data?.id);
  
  const videoThumbnailUrl = game.field_video_thumbnail
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + game.field_video_thumbnail
    : (videoThumbnailField?.attributes?.uri?.url
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + videoThumbnailField.attributes.uri.url
      : false);

  const userId = game.relationships?.uid?.data?.id || null;
  const description = game.attributes?.body?.processed || '';
  const descriptionSimple = (description && parse(description).text) || '';
  const rating = Number(game.field_ratingb || game.attributes?.field_ratingb) || '';
  const totalVotes = Number(game.attributes?.field_total_votes) || 0;
  const path = game.path || game.attributes?.path?.alias || '';
  const status = game.attributes?.status || false;
  const featured = game.attributes?.field_featured || '';
  const featuredUntil = game.attributes?.field_featured_until || '';

  const categoryIds = game.relationships?.field_category?.data.map((field) => field.id) || [];
  const categoryFields = (included && included.filter((field) => categoryIds.includes(field.id))) || [];
  const categories = categoryFields
    .map((field) => ({
      id: field.id,
      tid: field.attributes?.drupal_internal__tid,
      name: field.attributes?.name,
      path: field.attributes?.path.alias
    }))
    .filter((field) => !!field.path);

  const embedUrl = game.attributes?.field_link || false;

  const amazonStoreLink = game.attributes?.field_amazon_store_link || '';
  const googlePlayStoreLink = game.attributes?.field_google_play_store_link || '';
  const appleAppStoreLink = game.attributes?.field_apple_app_store_link || '';
  const crossPomotion = game.attributes?.field_cross_promotion || false;
  const ioLink = game.attributes?.field_more_io_games || false;
  const supportsMobile = game.attributes?.field_mobile || false;
  const youtubeLinks = game.attributes?.field_youtube_links || false;

  const totalCount = Number(meta?.count || -1);

  return {
    id: game.id,
    nid: game.nid || game.attributes.drupal_internal__nid,
    title: game.title || game.attributes.title,
    thumbnailUrl,
    promoUrl,
    videoThumbnailUrl,
    userId,
    description,
    descriptionSimple,
    rating,
    totalVotes,
    path,
    status,
    featured,
    featuredUntil,
    categories,
    embedUrl,
    amazonStoreLink,
    googlePlayStoreLink,
    appleAppStoreLink,
    crossPomotion,
    ioLink,
    supportsMobile,
    totalCount,
    youtubeLinks,
  }
}

export function formatCategory (category, included) {
  const thumbnailField = included && included.find((field) => field.id === category.relationships?.field_thumbnail?.data?.id);
  const thumbnailUrl = thumbnailField?.attributes?.uri?.url
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailField.attributes.uri.url
    : '';

  const description = category.attributes?.description?.processed || '';
  const descriptionSimple = (description && parse(description).text) || '';

  const path = category.attributes?.path?.alias || '';

  return {
    id: category.id,
    tid: category.attributes.drupal_internal__tid,
    name: category.attributes.name,
    thumbnailUrl,
    description,
    descriptionSimple,
    path
  }
}

export function formatOrder (order) {
  const item = 'Tokens';
  const date = formatDistance(new Date(order.attributes.completed), new Date(), { addSuffix: true });
  const price = `${order.attributes.total_price.formatted} ${order.attributes.total_price.currency_code}`;

  return {
    id: order.id,
    item,
    date,
    price
  }
}
