import Link from 'next/link';
import { useState, useEffect } from 'react';
import { faBars, faShoppingBag } from '@fortawesome/free-solid-svg-icons';

import { Slot, If, toClassName, createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { Icon, Flex, Button, Container, Dropdown } = shared;

import styles from './index.module.scss';
import { useAuthStore } from '@/stores/auth'; 
import IoSearch from '@/components/IoSearch';
import { useLogout } from '@/hooks/logout';

export default createComponent('AppHeader__Mobile', { styles }, function MobileHeader ({ mergeClassNames }, props) {
  const [ authState ] = useAuthStore();
  const [ scrolledAway, setScrolledAway ] = useState(false);

  const logoPath = process.env.APP_CONFIG.LOGO_PATH;
  const logoMonoGramPath = process.env.APP_CONFIG.LOGO_MONOGRAM_PATH;

  useEffect(() => {
    const handler = () => {
      const y = window.scrollY
      if (y === 0) setScrolledAway(false)
      else setScrolledAway(true)
    }
    document.addEventListener('scroll', handler)

    if (window.scrollY !== 0) setScrolledAway(true)

    return () => {
      document.removeEventListener('scroll', handler)
    }
  }, []);

  const toggleLogo = (showLogo) => {
    document
      .querySelector('.AppHeader__Mobile__Logo')
      .classList[showLogo ? 'remove' : 'add']('AppHeader__Mobile__Logo--hidden');
  };

  const className = mergeClassNames(toClassName('AppHeader__Mobile', { scrolledAway }));

  const logout = useLogout();

  return (
    <Container className={className} wide>
      <Flex wide>
        <Flex alignCenter oneQuarter>
          {props.children}
          <Link href='/'>
            {/* Intentionally squished markup here so no whitespace */}
            <a className='AppHeader__Mobile__Logo' style={{fontSize: 0}}><img
              src={logoMonoGramPath}
              alt='iogames.space'
              width={46}
              height={35}
            /><img
              src={logoPath}
              alt='iogames.space'
              width={190}
              height={28}
            /></a>
          </Link>
        </Flex>
        <Flex alignCenter justifyCenter half>
          <IoSearch
            onBlur={() => toggleLogo(true)}
            onFocus={() => toggleLogo(false)}
          />
        </Flex>
        <Flex alignCenter justifyEnd oneQuarter>
          <Dropdown right>
            <Slot name='trigger'>
              <Button primary ariaLabel='Toggle Mobile Dropdown Menu'>
                <Icon.FA icon={faBars} />
              </Button>
            </Slot>
            <Slot name='content'>
              <a className='DropdownItem Flex Flex--alignCenter' href='https://bluewizard.threadless.com/' target='_blank'>
                <Icon.FA icon={faShoppingBag} style={{ height:'1rem', width: '1rem' }} />&nbsp;&nbsp;
                <span>Merch</span>
              </a>
              <Dropdown.Divider />
              {
                If(authState.ready && !!authState.auth, () => (<>
                  <Dropdown.Link href='/account/edit'>My Account</Dropdown.Link>
                  <Dropdown.Item onClick={logout}>Sign Out</Dropdown.Item>
                </>))
                .EndIf()
              }
              {
                If(authState.ready && !authState.auth, () => (<>
                  <Dropdown.Link href='/account/register'>Sign Up</Dropdown.Link>
                  <Dropdown.Link href='/account'>Sign In</Dropdown.Link>
                </>))
                .EndIf()
              }
              <Dropdown.Divider />
              {
                If(authState.ready && !!authState.auth, () => (<>
                  <Dropdown.Link href='/developer'>For Developers</Dropdown.Link>
                </>))
                .EndIf()
              }
              {
                If(authState.ready && !authState.auth, () => (<>
                  <Dropdown.Link href='/account'>For Developers</Dropdown.Link>
                </>))
                .EndIf()
              }
            </Slot>
          </Dropdown>
        </Flex>
      </Flex>
    </Container>
  );
});
